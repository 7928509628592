<template>
  <div>
    <headers />
    <div class="bg">

      <div class="line1"></div>
      <div class="if">
        <div class="text1">
          <div>代理商查询:</div>
          <div class="con">
            代理商编号：<input v-model="params.agentNo" style="height: 1.5rem !important;font-size: 1rem;border-radius: 0.2rem;" /><br>
            手机后四位：<input v-model="params.last4No" style="height: 1.5rem !important;font-size: 1rem;border-radius: 0.2rem;" /><br>
            <el-button style="font-size: 1rem;"  type="primary" @click="getAgentInfo">查询</el-button>
          </div>
        </div>
      </div>

      <div v-if="resultShow" style="position: relative;display: flex;justify-content: center;">

        <el-card :class="getLevelClass(agentInfo.level)"  style="width: 15rem;font-size: 1rem; position: relative" :body-style="{ padding: '0px' }">
          <img :src="agentInfo.avatarUrl" class="image">

          <div style="margin-left: 30%">
            编号：<span>{{ agentInfo.agentNo}}</span>
          </div>
          <div v-if="agentInfo.name" style="margin-left: 30%">
            名称：<span>{{ agentInfo.name }}</span>
          </div>
          <div style="margin-left: 30%">
            姓名：<span>{{ agentInfo.contacts }}</span>
          </div>
<!--          <div style="margin-left: 30%">-->
<!--            等级：<span><i class="el-icon-s-flag" style="color: gold;"/>金牌</span>-->
<!--          </div>-->
          <div style="text-align: center">
              <span style="font-size: .8rem;">

              </span>
            <div class="bottom clearfix">
              <time class="time">数壤官方认证代理商资质</time>
            </div>
          </div>
        </el-card>


      </div>


      <div class="line1 blue"></div>
      <div class="if">
        <div class="text1">
          <div>如果你:</div>
          <div class="con">· 是各种制造业行业大拿、协会负责人等 <br>· 拥有广泛行业和企业人脉资源<br> · 正在寻找快实施、稳利润的产品<br/>· 正在寻找低投入、高回报项目<br/>· 想要加入数字化转型浪潮</div>
        </div>
        <div class="text2">欢迎你加入  “数字厂长” 代理商阵营，这里你将会收获你想要的！</div>
      </div>


      <div class="titleHed hen2">
        <div class="titleline"></div>
        <div class="titlename">适用行业</div>
        <div class="titleline"></div>
      </div>
      <div class="shuzi">“数字厂长”广泛适用多种制造业，包括但不限于：</div>
      <div class="tagbox">
        <div class="tag">机械设备</div>
        <div class="tag">轨道交通</div>
        <div class="tag">汽车制造</div>
        <div class="tag">专用设备</div>
        <div class="tag">铸造</div>
        <div class="tag">半导体</div>
        <div class="tag">注塑冲压</div>
        <div class="tag">泵</div>
        <div class="tag">阀门机械制造</div>
        <div class="tag">医疗器械</div>
        <div class="tag">智能制造</div>
        <div class="tag">非金属矿物制品</div>
        <div class="tag">电气机械和器材</div>
        <div class="tag">传感设备</div>
        <div class="tag">电器设备</div>
        <div class="tag">冶金</div>
        <div class="tag">集成电路</div>
        <div class="tag">高分子材料</div>
        <div class="tag">机加工和模具</div>
        <div class="tag">通用航空</div>
        <div class="tag">金属材料</div>
        <div class="tag">轴承</div>
        <div class="tag">齿轮制造</div>
        <div class="tag">通用零部件制造</div>
        <div class="tag">仪器仪表</div>
        <div class="tag">家具制造</div>
      </div>

    </div>
    <footers />
  </div>
</template>
<script>
import headers from '@/components/head';
import footers from '@/components/foot';
import redirect from "@/utils/redirect";
import pcRem from "@/utils/pcrem";
import agentApi from "@/assets/api/agent/agent";

export default {
  mixins: [redirect,pcRem],
  components: {
    headers,
    footers
  },
  data(){
    return{
      resultShow:false,
      params:{
        agentNo:'',
        last4No:''
      },
      agentInfo:{},
      bageClass : 'jinpai'
    }
  },
  methods:{
    getLevelClass(level){
      switch (String(level)) {
        case '1':
          this.bageClass = 'zuanshi';
          break;
        case '2':
          this.bageClass = 'jinpai';
          break;
        case '3':
          this.bageClass = 'yinpai';
          break;
        case '4':
          this.bageClass = 'tongpai';
          break;
      }
      return this.bageClass;

    },
    getAgentInfo(){
      // this.agentInfo = {
      //     "id": 3,
      //     "agentNo": "000051",
      //     "name": "中星",
      //     "shortName": "zx",
      //     "contacts": "苏生安",
      //     "telephone": "15643234245",
      //     "level": 4,
      //     "avatar": "{\"fileName\":\"blob\",\"size\":69605,\"ossId\":1416,\"url\":\"https://plm-document.obs.cn-east-3.myhuaweicloud.com:443/agent-avatar%2Fprovider_0%2F2024%2F05%2F30%2Fcf5fc608c2e84cb88a025c370b3ebadb%2Fblob\"}",
      //     "province": "310000",
      //     "city": "310000",
      //     "area": "310110",
      //     "remark": "挖掘",
      //     "strongArea": "装备制造",
      //     "createTime": "2024-05-30 13:40:32",
      //     "createBy": "admin",
      //     "updateTime": "2024-05-30 15:23:32",
      //     "updateBy": "admin",
      //     "isDelete": 0
      // }
      if(this.params.agentNo === null || '' === this.params.agentNo){
        this.$message.warning("请输入代理商编号！")
        return;
      }
      if(this.params.last4No === null || '' === this.params.last4No){
        this.$message.warning("请输入代理商手机号后四位！")
        return;
      }
      agentApi.agentSearch(this.params).then((res) => {
        if(res.code === 200){
          if(res.data === null && res.msg !== '' ){
            this.$message.warning(res.msg)
          }else{
            this.resultShow = true;
            this.agentInfo = res.data;
          }
        }else {
          this.$message.error(res.msg)
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
//1920*1080为设计稿，头部字体大小为25px
$ratio: 1920 / 76.8; // 缩放比   25

// 返回对应屏幕下的rem值
@function px2rem($px) {
  @return $px / $ratio + rem;
}
.bg{
  width: 100%;
  // height: px2rem(5920);
  height: 100%;
  background: url(../../assets/image/partner/bg1.png) center no-repeat;
  background-size: 100% 100%;
  overflow: hidden;
}
.h1{
  margin: px2rem(131) auto 0;
  width: px2rem(1920);
  height: px2rem(221);
  display: block;
}
// .h2{
//   margin-top: px2rem(37);
//   height: px2rem(105);
// }
.line1{
  position: relative;
  margin: px2rem(70) auto 0;
  width: px2rem(650);
  height: px2rem(1);
  border-top: px2rem(1) solid;
  border-image: linear-gradient(270deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1), rgba(255, 255, 255, 1), rgba(255, 255, 255, 0)) 1 1;
  &:after{
    position: absolute;
    top: px2rem(-4.5);
    right: px2rem(198);
    content: '';
    width: px2rem(10);
    height: px2rem(10);
    background: #FFFFFF;
    border-radius: 50%;
  }
  &.blue{
    &::after{
      background: #9EFCFF;
      left: px2rem(198);
    }
  }
}
.if{
  margin: auto;
  width: px2rem(800);
  font-size: px2rem(24);
  font-family: MicrosoftYaHei;
  color: rgba(255,255,255,0.8);
  line-height: px2rem(72);
  .text1{
    display: flex;
    .con{
      margin-left: px2rem(60);
    }
  }
}
.titleHed{
  &.hen1{
    margin-top: px2rem(144);
  }
  &.hen2{
    margin-top: px2rem(75);
  }
  position: relative;
  margin: auto;
  width: px2rem(1200);
  display: flex;
  align-items: center;
  &::before{
    position: absolute;
    content: '';
    width: px2rem(10);
    height: px2rem(10);
    background: #FFFFFF;
    border-radius: 50%;
    left: 0;
    top: px2rem(16);
  }
  &::after{
    position: absolute;
    content: '';
    width: px2rem(10);
    height: px2rem(10);
    background: #FFFFFF;
    border-radius: 50%;
    right: 0;
    top: px2rem(16);
  }
  .titleline{
    width: 50%;
    height: 1px;
    background:  #FFFFFF;
  }
  .titlename{
    margin: 0 px2rem(40);
    font-size: px2rem(32);
    font-family: MicrosoftYaHei;
    color: #FFFFFF;
    line-height: px2rem(42);
    word-break: keep-all;
  }
}
.therehas{
  margin: px2rem(40) auto 0;
  width: px2rem(1200);
  display: flex;
  justify-content: space-between;
  .box{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: px2rem(334);
    height: px2rem(324);
    border-radius: px2rem(30);
    border: 2px solid #9EFCFF;
  }
  .imgBox{
    margin-top: px2rem(40) ;
    height: px2rem(100);
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .img1{
    width: px2rem(89);
    height: px2rem(100);
  }
  .img2{
    width: px2rem(88);
    height: px2rem(82);
  }
  .img3{
    width: px2rem(96);
    height: px2rem(96);
  }
  .tag{
    margin-top: px2rem(30);
    padding: 0 px2rem(30);
    height: px2rem(60);
    line-height: px2rem(60);
    border-radius: px2rem(30);
    border: px2rem(2) solid #9DFBFF;
    font-size: px2rem(32);
    color: #FFFFFF;
    background: #004EA7;
  }
  .text{
    margin-top: px2rem(10);
    font-size: px2rem(24);
    color: rgba(255,255,255,0.85);
    line-height: px2rem(31);
    text-align: center;
  }
}
.shuzi{
  margin-top: px2rem(20);
  text-align: center;  
  font-size: px2rem(24);
  font-family: MicrosoftYaHei;
  color: rgba(255,255,255,0.85);
  line-height: px2rem(31);
}
.whatprod{
  margin: px2rem(40) auto 0;
  width: px2rem(1200);
  display: flex;
  justify-content: space-between;
  .box{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: px2rem(286);
    height: px2rem(386);
    border-radius: px2rem(30);
    border: 2px solid #9EFCFF;
  }
  .imgBox{
    margin-top: px2rem(40) ;
    height: px2rem(100);
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .img4{
    width: px2rem(94);
    height: px2rem(94);
  }
  .img5{
    width: px2rem(88);
    height: px2rem(88);
  }
  .img6{
    width: px2rem(82);
    height: px2rem(82);
  }
  .img7{
    width: px2rem(84);
    height: px2rem(84);
  }
  .tag{
    margin-top: px2rem(30);
    padding: 0 px2rem(30);
    height: px2rem(60);
    line-height: px2rem(60);
    border-radius: px2rem(30);
    border: px2rem(2) solid #9DFBFF;
    font-size: px2rem(32);
    color: #FFFFFF;
    background: #004EA7;
  }
  .text{
    margin-top: px2rem(10);
    font-size: px2rem(24);
    color: rgba(255,255,255,0.85);
    line-height: px2rem(31);
    text-align: center;
  }
}
.helpyou{
  margin: px2rem(40) auto 0;
  width: px2rem(1200);
  display: flex;
  justify-content: space-between;
  .box{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: px2rem(286);
    height: px2rem(324);
    border-radius: px2rem(30);
    border: 2px solid #9EFCFF;
  }
  .imgBox{
    margin-top: px2rem(40) ;
    height: px2rem(100);
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .img8{
    width: px2rem(94);
    height: px2rem(94);
  }
  .img9{
    width: px2rem(88);
    height: px2rem(79);
  }
  .img10{
    width: px2rem(80);
    height: px2rem(77);
  }
  .img11{
    width: px2rem(92);
    height: px2rem(92);
  }
  .tag{
    margin-top: px2rem(30);
    padding: 0 px2rem(30);
    height: px2rem(60);
    line-height: px2rem(60);
    border-radius: px2rem(30);
    border: px2rem(2) solid #9DFBFF;
    font-size: px2rem(32);
    color: #FFFFFF;
    background: #004EA7;
  }
  .text{
    margin-top: px2rem(10);
    font-size: px2rem(24);
    color: rgba(255,255,255,0.85);
    line-height: px2rem(31);
    text-align: center;
  }
}
.tagbox{
  display: flex;
  flex-wrap: wrap;
  padding: px2rem(5);
  margin: px2rem(40) auto;
  width: px2rem(1200);
  border-radius: px2rem(30);
  border: 2px solid #9EFCFF;
  box-sizing: border-box;
  .tag{    
    margin: px2rem(10);
    padding: 0 px2rem(30);
    height: px2rem(60);
    line-height: px2rem(60);
    border-radius: px2rem(30);
    border: px2rem(2) solid #9DFBFF;
    font-size: px2rem(32);
    color: #FFFFFF;
    background: #004EA7;
  }
}
.stepBox{
  margin: px2rem(42) auto 0;
  width: px2rem(1200);
  position: relative;
  .stepline{
    position: absolute;
    z-index: 1;
    top: px2rem(-20);
    left: px2rem(50);
    width: px2rem(1);
    height: px2rem(480);
    border-left: 1px solid;
    border-image: linear-gradient(180deg, rgba(204, 253, 255, 0), rgba(204, 253, 255, 1), rgba(177, 252, 255, 1), rgba(157, 251, 255, 0)) 1 1;
    &.stepline2{
      height: px2rem(550);
    }
    &.stepline3{
      height: px2rem(425);
    }
  }
  .step{
    position: relative;
    z-index: 2;
    margin-top: px2rem(80);
    display: flex;
    align-items: center;
    &:first-child{
      margin-top: 0;
    }
    .num{
      width: px2rem(100);
      height: px2rem(60);
      line-height: px2rem(60);
      text-align: center;
      border-radius: px2rem(30);
      border: px2rem(2) solid #9DFBFF;
      font-size: px2rem(32);
      color: #FFFFFF;
      background: #004EA7;
    }
    .steptext{
      margin-left: px2rem(40);
      width: 42rem;      
      color: #FFFFFF;
      line-height: px2rem(37);      
      font-size: px2rem(28);
      font-family: MicrosoftYaHei;
    }
  }
}
.welcome{
  margin-top: px2rem(102);
  width: px2rem(1000);
  height: px2rem(66);
  text-align: center;
  line-height: px2rem(66);
  border-radius: px2rem(40);
  border: px2rem(2) solid #9EFCFF;
  font-size: px2rem(24);
  font-family: MicrosoftYaHei;
  color: rgba(255,255,255,0.85);

}
.jieshi{
  margin-top: px2rem(180);
  height: px2rem(50);
  background: #000D09;
  font-size: px2rem(20);
  font-weight: 400;
  color: #FFFFFF;
  text-align: center;
  line-height: px2rem(50);
}
// ::v-deep .footer{
//   position: relative;
//   z-index: 1;
//   margin-top: -16rem
// }

.time {
  font-size: .8rem;
  color: #999;
}

.bottom {
  margin-top: .1rem;
  line-height: 1.28rem;
}

.button {
  padding: 0;
  float: right;
}

.image {
  width: 100%;
  display: block;
}

.jinpai:before {
  content: "金牌";
  position: absolute;
  top: 5px;
  right: -15px;
  padding: 5px 22px;
  background-color:#FFD700;
  transform: rotate(45deg);
  font-size:.8rem;
  color: #FFFFFF;
}
.zuanshi:before {
  content: "钻石";
  position: absolute;
  top: 5px;
  right: -15px;
  padding: 5px 22px;
  background-color: #63dfff;
  transform: rotate(45deg);
  font-size:.8rem;
  color: #FFFFFF;
}

.yinpai:before {
  content: "银牌";
  position: absolute;
  top: 5px;
  right: -15px;
  padding: 5px 22px;
  background-color: #C0C0C0;
  transform: rotate(45deg);
  font-size:.8rem;
  color: #FFFFFF;
}

.tongpai:before {
  content: "铜牌";
  position: absolute;
  top: 5px;
  right: -15px;
  padding: 5px 22px;
  background-color: #CD7F32;
  transform: rotate(45deg);
  font-size:.8rem;
  color: #FFFFFF;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}

.clearfix:after {
  clear: both
}
</style>
